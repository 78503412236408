<template>
  <div class="sticky-footer sticky-content fix-bottom fixed">
    <router-link to="/" class="sticky-link active">
      <i class="w-icon-home"></i>
      <p>{{ $store.state.yuyanbao.shouye }}</p>
    </router-link>
    <router-link :to="{ path: 'products' }" class="sticky-link">
      <i class="w-icon-category"></i>
      <p>{{ $store.state.yuyanbao.shangpin }}</p>
    </router-link>

    <div class="header-search hs-toggle dir-up">
      <a class="sticky-link search-toggle" id="tabbarfuwu">
        <i class="w-icon-orders"></i>
        <p>{{ $store.state.yuyanbao.fuwu }}</p>
      </a>
      <div class="input-wrapper" style="right: -2rem; display: block">
        <div
          v-for="(item, index) in fuwu"
          :key="index"
          style="margin-bottom: 10px"
          @click="tap_tabbar"
        >
          <router-link :to="{ path: '/service', query: { ukey: item.ukey } }">
            {{ duoyuyan(item) }}
          </router-link>
        </div>
      </div>
    </div>
    <router-link to="/account" class="sticky-link search-toggle">
      <i class="w-icon-account"></i>
      <p>{{ $store.state.yuyanbao.wodezhanghu }}</p>
    </router-link>
  </div>
</template>

<script>
import { getFuWu } from "@/api/fuwu";

export default {
  data() {
    return {
      fuwu: [],
    };
  },
  mounted() {
    this.get_fuwu_http();
  },
  methods: {
    get_fuwu_http() {
      getFuWu().then((data) => {
        if (data.status === "SUCCESSED") this.fuwu = data.data;
      });
    },
    tap_tabbar() {
      document.getElementById("tabbarfuwu").click();
    },
  },
};
</script>

<style></style>
