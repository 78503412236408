class Config {
  YUYAN = 'fr'
  TITLE = 'Badaddict'
  DOMAIN = 'https://admin.yxlmitu.fr'
  TIMEOUT = 30000
  TIMEOUTUPLOAD = 1800000
  TUPIANBASE = this.DOMAIN + '/public/'
}

module.exports = new Config()
