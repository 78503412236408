<template>
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu-overlay"></div>
    <span class="mobile-menu-close"><i class="close-icon"></i></span>
    <div class="mobile-menu-container scrollable">
      <div class="input-wrapper">
        <input
          type="text"
          class="form-control"
          name="search"
          autocomplete="off"
          :placeholder="$store.state.yuyanbao.sousuo"
          v-model="search"
          required
        />
        <button class="btn btn-search" @click="search_http">
          <i class="w-icon-search"></i>
        </button>
      </div>
      <div class="tab">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <span @click="tab = 1" class="nav-link active">
              {{ $store.state.yuyanbao.caidan }}
            </span>
          </li>
          <li class="nav-item">
            <span @click="tab = 2" class="nav-link">
              {{ $store.state.yuyanbao.fenlei }}
            </span>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div
          class="tab-pane"
          :class="{ active: tab === 1 }"
          @click="tap_tabbar"
        >
          <ul class="mobile-menu">
            <li>
              <router-link to="/">
                {{ $store.state.yuyanbao.shouye }}
              </router-link>
            </li>
            <li class="show">
              <a @click.stop>{{ $store.state.yuyanbao.fuwu }}</a>
              <ul style="display: block">
                <li v-for="(item, index) in services" :key="index">
                  <router-link
                    :to="{ path: '/service', query: { ukey: item.ukey } }"
                  >
                    {{ duoyuyan(item) }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/blog">
                {{ $store.state.yuyanbao.boke }}
              </router-link>
            </li>
            <li>
              <router-link to="/aboutus">
                {{ $store.state.yuyanbao.guanyuwomen }}
              </router-link>
            </li>
            <li>
              <router-link to="/contactus">
                {{ $store.state.yuyanbao.lianxiwomen }}
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="tab-pane"
          :class="{ active: tab === 2 }"
          @click="tap_tabbar"
        >
          <ul class="mobile-menu">
            <li class="show" v-for="(item, index) in categorys" :key="index">
              <router-link
                :to="{ path: '/products', query: { ukey: item.ukey } }"
                style="color: #eee"
              >
                {{ duoyuyan(item) }}
              </router-link>
              <ul
                style="display: block"
                v-if="item.children && item.children.length > 0"
              >
                <li
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  class="show"
                >
                  <router-link
                    :to="{ path: '/products', query: { ukey: item1.ukey } }"
                    style="color: #eee"
                  >
                    {{ duoyuyan(item1) }}
                  </router-link>
                  <ul v-if="item1.children && item1.children.length > 0">
                    <li v-for="(item2, index2) in item1.children" :key="index2">
                      <router-link
                        :to="{ path: '/products', query: { ukey: item2.ukey } }"
                        style="color: #eee"
                      >
                        {{ duoyuyan(item2) }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShangPinFenLei } from "@/api/shanpin_fenlei";
import { getFuWu } from "@/api/fuwu";

export default {
  data() {
    return {
      categorys: [],
      tab: 1,
      services: [],
      search: "",
    };
  },
  mounted() {
    this.get_category_http();
    this.get_service_http();
  },
  methods: {
    tap_tabbar() {
      document.getElementsByClassName("mobile-menu-close")[0].click();
    },
    get_category_http() {
      getShangPinFenLei().then((data) => {
        if (data.status === "SUCCESSED") {
          this.categorys = data.data;
          this.category_select(this.categorys);
        }
      });
    },
    category_select(ct) {
      for (let i = 0, len = ct.length; i < len; i += 1) {
        if (ct[i].children && ct[i].children.length > 0)
          this.category_select(ct[i].children);
      }
    },
    get_service_http() {
      getFuWu().then((data) => {
        if (data.status === "SUCCESSED") this.services = data.data;
      });
    },
    search_http() {
      if (this.search === "") return;
      this.$router.push({
        path: "/search",
        query: {
          search: this.search,
        },
      });
      if (this.$route.name === "Search") window.location.reload();
    },
  },
};
</script>

<style></style>
