import { YUYAN, TITLE } from '../config'

export default {
  width: window.innerWidth,
  login: parseInt(localStorage.getItem('login') || '0'),
  yonghu: JSON.parse(localStorage.getItem('yonghu') || '{}'),
  yuyan: localStorage.getItem(TITLE + 'yuyan') || YUYAN,
  yuyans: JSON.parse(localStorage.getItem(TITLE + 'yuyans') || '[]'),
  yuyanbao: JSON.parse(localStorage.getItem(TITLE + 'yuyanbao') || '{}'),
  shezhi: JSON.parse(localStorage.getItem(TITLE + 'shezhi') || '{}'),
  shezhimitu: JSON.parse(localStorage.getItem(TITLE + 'shezhimitu') || '{}'),
  cart: JSON.parse(
    localStorage.getItem('cart') ||
      JSON.stringify({
        total: 0,
        count: 0,
        products: [],
      })
  ),
  loading: false,
}
