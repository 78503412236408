import { TIMEOUTUPLOAD } from '@/config'
import HTTP from '@/http'

export function upload(data = {}) {
  HTTP.defaults.timeout = TIMEOUTUPLOAD
  return HTTP({
    url: 'upload',
    method: 'post',
    data,
  })
}
