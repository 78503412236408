<template>
  <div>
    <div
      style="
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff44;
        z-index: 20000;
      "
      class="app-center"
      v-if="$store.state.loading"
    >
      <div class="app-rainbow-container-main">
        <div class="app-rainbow-container">
          <div class="app-green"></div>
          <div class="app-pink"></div>
          <div class="app-blue"></div>
        </div>
      </div>
    </div>
    <h1 class="d-none">Badaddict</h1>
    <router-view />
    <a id="scroll-top" class="scroll-top" href="#top" title="Top" role="button">
      <i class="w-icon-angle-up"></i>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70">
        <circle
          id="progress-indicator"
          fill="transparent"
          stroke="#000000"
          stroke-miterlimit="10"
          cx="35"
          cy="35"
          r="34"
          style="stroke-dasharray: 16.4198, 400"
        ></circle>
      </svg>
    </a>
    <transition name="el-fade-in-linear">
      <div
        v-show="apptishi"
        style="
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.4);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 20000;
        "
        @click="apptishi = !apptishi"
      >
        <div
          style="
            width: 100%;
            max-width: 800px;
            padding: 20px;
            margin: 20px;
            border-radius: 1rem;
            background: #fff;
            min-height: 50vh;
            max-height: 90vh;
            background-position: center;
            background-size: cover;
            background-image: url(/assets/images/newsletter-1.jpg);
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
          "
        >
          <div>
            <h4>Get Up to 25% Off</h4>
            <h2>假期提示或其他信息提示</h2>
            <p>
              Subscribe to the Wolmart market newsletter to receive updates on
              special offers.
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getSheZhi } from "@/api/shezhi";
import { getYuYan, getYuYanBao } from "@/api/yuyan";
export default {
  data() {
    return {
      apptishi: false,
    };
  },
  mounted() {
    this.$store.commit("set_loading", false);
    // localforage使用
    // this.$localforage.getItem("lfshezhi").then((data) => console.log(data));
    // this.$localforage.setItem("lfshezhi", shezhi);
    getSheZhi().then((data) => {
      if (data.status === 1) {
        let shezhi = {};
        for (let i = 0, len = data.data.length; i < len; i += 1) {
          shezhi[data.data[i].jian] =
            data.data[i]["zhi_" + this.$store.state.yuyan] || data.data[i].zhi;
        }

        this.$store.commit("set_shezhi", shezhi);
      }
    });
    getSheZhi({ mitu: "mitu" }).then((data) => {
      if (data.status === 1) {
        if (data.status === 1) {
          let shezhi = {};
          for (let i = 0, len = data.data.length; i < len; i += 1) {
            shezhi[data.data[i].jian] =
              data.data[i]["zhi_" + this.$store.state.yuyan] ||
              data.data[i].zhi;
          }
          this.$store.commit("set_shezhi_mitu", shezhi);
        }
      }
    });
    getYuYan({ zhuangtai: 1 }).then((data) => {
      if ((data.status = 1)) this.$store.commit("set_yuyans", data.data);
    });
    getYuYanBao().then((data) => {
      if (data.status === 1) {
        let yuyanbao = {};
        for (let i = 0, len = data.data.length; i < len; i += 1) {
          yuyanbao[data.data[i].jian] =
            data.data[i]["zhi_" + this.$store.state.yuyan] || data.data[i].zhi;
        }
        this.$store.commit("set_yuyanbao", yuyanbao);
      }
    });
    window.addEventListener("resize", () => {
      this.$store.commit("set_width");
    });
  },
};
</script>

<style>
@import "./assets/yxl.css";
</style>
